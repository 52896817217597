<template>
    <div class="admin-view">
        <div class="admin-nav">
            <div class="admin-btn" @click="go_to('priority')">
                <p>טעינת נתונים</p>
            </div>
            <div class="admin-btn" @click="go_to('catalog')">
                <p>ניהול אלבום</p>
            </div>
            <div class="admin-btn" @click="go_to('users')">
                <p>משתמשים</p>
            </div>
            <div class="admin-btn" @click="go_to('agents')">
                <p>סוכנים</p>
            </div>
            <div class="admin-btn" @click="go_to('sales')">
                <p>מבצעים</p>
            </div>
            <div class="admin-btn" @click="go_to('goals')">
                <p>יעדים</p>
            </div>
            <div class="admin-btn" @click="go_to('shows')">
                <p>תצוגות</p>
            </div>
            <div class="admin-btn" @click="go_to('admin_applications')">
                <p>אפליקציות</p>
            </div>
            <div v-if="false" class="admin-btn" @click="go_to('zoe')">
                <p>ZOE</p>
            </div>
            <div class="admin-btn" @click="go_to('shivuk_rosman')">
                <p>שיווק רוסמן</p>
            </div>
            <div class="admin-btn" @click="go_to('system')">
                <p>מערכת</p>
            </div>
            <div class="admin-btn" @click="go_to('human_resources')">
                <p>משאבי אנוש</p>
            </div>
        </div>
        <div class="admin-router">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { projectFirestore, projectFunctions } from '../../firebase/config';
import { fix_images } from '../../Methods/Storage';
import router from '../../router';
import store from '../../store';
import XLSX from "xlsx";
import { ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';

export default {
setup(){
    
    const go_to = (path) => {
        router.push('/admin/' + path);
    }

    const upload =async () => {
        // await projectFunctions.httpsCallable('fix_images', {timeout: 360000})();
        const el = document.getElementById('upload')
        el.click();
    }

    const excel = () => {
        let file = document.getElementById("upload").files[0];
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = e => {
                const bufferArray = e.target.result;
                const workbook = XLSX.read(bufferArray, {
                    type: "buffer",
                    cellDates: true,
                    dateNF: "yyyy/mm/dd;@"
                });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                resolve(data);
            };
            fileReader.onerror = error => {
            reject(error);
            };
        })
        promise.then(async data => {
            console.log(data);
            await projectFunctions.httpsCallable('fix_items', {timeout: 360000})({data:data});
        })
    }

    const make_order = async () => {
        await projectFunctions.httpsCallable("new_order")();
    }

    return{
        go_to, upload, excel,make_order
    }
}
}
</script>

<style scoped>
.admin-view{
    width: 100%;
    height: 100%;
}
.admin-nav{
    width: 100%;
    height: 10%;
    background: var(--secondary);
    display: grid;
    grid-auto-columns: 120px;
    grid-auto-flow: column;
    padding: 10px;
    overflow-x: auto;
    gap: 5px;
    overflow-x: auto;
}
.admin-btn{
    width: 120px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(123deg ,#7367f0,rgba(110,100,238,.7));
    border-radius: 5px;
    box-shadow: 0 0 4px 1px rgb(110 100 238 / 20%);
    color: #d0d2d6!important;
    transition: 0.25s;
    text-shadow: 0 0px 2px rgba(0, 0, 0, 0.445);
}
.admin-btn:hover {
    box-shadow: 0 0 10px 5px rgb(110 100 238 / 20%);
}
.admin-router{
    width: 100%;
    height: 90%;
    padding: 10px;
}
</style>