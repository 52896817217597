import Swal from 'sweetalert2';
import {projectFirestore, projectFunctions, projectStorage} from '../firebase/config';
import {slide_pop_successs, slide_pop_error} from './Msgs';

const upload_image = async (uid, file) => {
    const types = ['image/png', 'image/jpg', 'image/jpeg']
    if(!types.includes(file.type)){
        console.log(file.type);
        return 'Type'
    }
    const promise = await new Promise((resolve, reject) => {
        var storage = projectStorage.ref(`Users/${uid}/${file.name}`);
        var upload = storage.put(file);
        upload.on("state_changed",
            function progress(snapshot) {
                //var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //document.getElementById("progress").value = percentage;
            },
            function error() {
                slide_pop_error('שגיאה בעת העלאת הקובץ, פנה לתמיכה טכנית.');
                resolve('Error')
            },
            function complete() {
                resolve(true)
            }
        );
    })
    if(promise){
        const download_path = await new Promise((resolve, reject) => {
            setTimeout(async () => {
                var name = file.name.split('.')
                name[name.length - 2] = name[name.length - 2] + '_375x375'
                name = name.join('.')
                const url = projectStorage.ref(`Users/${uid}/${name}`);
                const download_url = await url.getDownloadURL()
                resolve(download_url)
            }, 4000);
        })
        return download_path
    }
    return promise
}

const upload_product_image = async (makat, file) => {
    const types = ['image/png', 'image/jpg', 'image/jpeg']
    if(!types.includes(file.type)){
        Swal.fire({
            icon: 'error',
            title: 'שגיאה',
            text: 'בחר קובץ תמונה תקני בבקשה'
        }).then(() => {
            return
        })
    }
    const promise = await new Promise((resolve, reject) => {
        let file_name = file.name.split('.')
        file_name = makat + '.' + file_name[file_name.length - 1];

        var storage = projectStorage.ref(`Products/${file_name}`);
        var upload = storage.put(file);
        upload.on("state_changed",
            function progress(snapshot) {
                //var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //document.getElementById("progress").value = percentage;
            },
            function error() {
                slide_pop_error('שגיאה בעת העלאת הקובץ, פנה לתמיכה טכנית.');
                resolve('Error')
            },
            function complete() {
                resolve(true)
            }
        );
    })
    if(promise){
        const download_path = await new Promise((resolve, reject) => {
            setTimeout(async () => {
                let file_name = file.name.split('.')
                file_name = makat + '_375x375.' + file_name[file_name.length - 1];

                const url = projectStorage.ref(`Products/${file_name}`);
                const download_url = await url.getDownloadURL()
                resolve(download_url)
            }, 4000);
        })
        return download_path
    }
    return promise
}

export {upload_image, upload_product_image}